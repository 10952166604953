import Header from '../../components/Header';
import Footer from '../../components/Footer';
import './index.scss';

function Impressum() {
  return (
    <main>
      <article className="impresssum">
        <div className="header-absolute-wrapper">
          <Header isWhite={true} />
        </div>
        <section className="section-head-wrapper">
          <div className="section-head">
            <div className="title">Impressum</div>
          </div>
        </section>
        <section className="impresssum-info">
          <div className="impresssum-info-1">
            <span>
              <span className="bold">Welcome to yulci.com.</span> This website
              is operated by yulci GmbH, based in Neuhausen am Rheinfall,
              Switzerland. We have made it our goal to develop a social media
              app with social goals. We are always happy to receive questions,
              criticism and suggestions for improvement. Or just want to say
              hello? We are always available for you.
            </span>
          </div>

          <div className="impresssum-info-2">
            <div className="title">Impressum</div>
            <span>
              <span className="bold">Company:</span> yulci GmbH
            </span>
            <span>
              <span className="bold">Place:</span> Neuhausen am Rheinfall
            </span>
            <span>
              <span className="bold">Postal adress:</span> c/o Trubegüetlistr.
              18
            </span>
            <span>
              <span className="bold">Country:</span> Switzerland
            </span>
            <span>
              <span className="bold">Responsible:</span> Dreni Morina
            </span>
            <span>
              <span className="bold">Contact:</span> dreni.morina (at) yulci.com
            </span>
            <span>
              <span className="bold">CH-ID-Number:</span> CH-290.4.021.473-4
            </span>
          </div>

          <div className="impresssum-info-3">
            <div className="title">Place of jurisdiction & Disclaimer</div>
            <span>
              The exclusive place of jurisdiction is Schaffhausen, Switzerland.
              We assume no liability for the accuracy of the information
              contained on the website or mobile app. We check our information
              regularly, but errors can still occur. Please feel free to contact
              us at any time if you have any questions.
            </span>
          </div>
        </section>
      </article>
      <Footer />
    </main>
  );
}

export default Impressum;
