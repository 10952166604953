import { Link } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MainCaruselCard from "../../components/MainCaruselCard";
import { Carousel, CarouselItem } from "../../components/Carousel";
import Bubble1 from "../../assets/bubble4.svg";
import AppleStore from "../../assets/app-store.svg";
import GoogleStore from "../../assets/google-store.svg";
import Mobile1 from "../../assets/mobile-1.png";
import ConnectingPeople from "../../assets/connecting-people.svg";
import More from "../../assets/more.svg";
import Mobile2 from "../../assets/mobile-2.png";
import { caruselItem, caruselTitle } from "./constants";
import "./index.scss";

function Main() {
  return (
    <main>
      <Header />
      <article className="main">
        <section className="section section-1">
          <img src={Bubble1} alt="bubble-1" className="bubble-1" />
          <div className="section-left-1">
            <div className="title-section">
              Live sustainable. Social Media with #nofilter, #noeffects.
            </div>
            <div className="text-section text-section-mobile">
              Social media but different. We create value by connecting the
              global community for sustainable living. Get to know with people
              with the same interests. We never share your data.
            </div>
            <div className="stores-wrapper">
              <a href="/">
                <img src={AppleStore} alt="apple-store" />
              </a>
              <a href="/">
                <img src={GoogleStore} alt="google-store" />
              </a>
            </div>
          </div>
          <div className="section-right-1">
            <img src={Mobile1} alt="mobile-1" className="mobile-1" />
          </div>
        </section>
        <section className="section section-2">
          <div className="section-left-2">
            <img src={ConnectingPeople} alt="connecting-people" />
          </div>
          <div className="section-right-2">
            <div className="title-section title-small">
              Discover your everyday life. We are always at your hand.
            </div>
            <div className="text-section-wrapper">
              <div className="text-section inner-border">
                Whether you're looking to learn more about
                environmentally-friendly products, connect with like-minded
                individuals, or stay up-to-date on the latest trends, our app is
                the perfect tool for you. Packed with features that make it a
                joy to use. So why wait?
              </div>
            </div>
            <a href="/" className="get-app">
              <div>Get the app</div> <img src={More} alt="more" />
            </a>
          </div>
        </section>
        <section className="section section-3">
          <div className="section-left-3">
            <div className="title-section title-small">
              Learn more about our values.
            </div>
            <div className="text-section">
              Corporate values are fundamental to our joint success. Although we
              are young, we have a clear vision..
            </div>
            <Link to={"/about"} className="learn-more-us">
              <div>Learn more about us</div> <img src={More} alt="more" />
            </Link>
          </div>
          <Carousel>
            {caruselItem.map((el, index) => (
              <CarouselItem>
                <MainCaruselCard
                  cardName={el}
                  caruselTitle={caruselTitle[index]}
                />
              </CarouselItem>
            ))}
          </Carousel>
        </section>
        <section className="section secton-4">
          <div className="section-left-4">
            <img src={Mobile2} alt="mobile-2" />
          </div>
          <div className="section-right-4">
            <div className="text-section-wrapper section-4-text-wrapper">
              <div className="title-section outer-border title-small">
                Login on the go. Share your interests.
              </div>
            </div>
            <div className="text-section outer-border-text">
              Create your profile and start with a first impression. We continue
              to develop social media. Soon vailable for free without any
              restrictions for IOS and Android.
            </div>
            <div className="stores-wrapper">
              <a href="/">
                <img src={AppleStore} alt="apple-store" />
              </a>
              <a href="/">
                <img src={GoogleStore} alt="google-store" />
              </a>
            </div>
          </div>
        </section>
      </article>
      <Footer />
    </main>
  );
}

export default Main;
