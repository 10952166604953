import ArrowRight from "../../assets/arrow-right-white.svg";
import Plant from "../../assets/plant.svg";
import "./index.scss";

function MainCaruselCard({ cardName, caruselTitle }) {

  const renderText = () => {
    switch (caruselTitle) {
      case "Our vision":
        return (
          <div className="text-section text">
            In the long-term, we want to offer a platform that connects people
            and makes conscious decisions easy and intuitive...
          </div>
        );
      case "Values":
        return (
          <div className="text-section text">
            At yulci we want to raise awareness and promote a sustainable
            economy to new people every day. We are...
          </div>
        );
      case "Our mission":
        return (
          <div className="text-section text">
            We are developing a digital ecosystem that differs from others. We
            work with all stakeholders to promote...
          </div>
        );
      case "Our purpose":
        return (
          <div className="text-section text">
            Our goal is to show people that they can easily integrate small
            things into everyday life and how easy it can be.
          </div>
        );
    }
  };

  const renderImage = () => {
    switch (cardName) {
      case "idea":
        return (
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_868_26998)">
              <path
                d="M9.79488 8.30544L6.81209 5.32264C6.40013 4.91068 5.73265 4.91068 5.32069 5.32264C4.90873 5.7346 4.90873 6.40208 5.32069 6.81404L8.30349 9.79684C8.71545 10.2088 9.38292 10.2088 9.79488 9.79684C10.2068 9.38488 10.2068 8.7174 9.79488 8.30544Z"
                fill="#377E82"
              />
              <path
                d="M5.34375 16.9453H1.05469C0.471727 16.9453 0 17.417 0 18C0 18.583 0.471727 19.0547 1.05469 19.0547H5.34375C5.92671 19.0547 6.39844 18.583 6.39844 18C6.39844 17.417 5.92671 16.9453 5.34375 16.9453Z"
                fill="#377E82"
              />
              <path
                d="M34.9453 16.9453H30.6562C30.0733 16.9453 29.6016 17.417 29.6016 18C29.6016 18.583 30.0733 19.0547 30.6562 19.0547H34.9453C35.5283 19.0547 36 18.583 36 18C36 17.417 35.5283 16.9453 34.9453 16.9453Z"
                fill="#377E82"
              />
              <path
                d="M30.6777 5.32264C30.2657 4.91068 29.5983 4.91068 29.1863 5.32264L26.2035 8.30544C25.7915 8.7174 25.7915 9.38488 26.2035 9.79684C26.6155 10.2088 27.2829 10.2088 27.6949 9.79684L30.6777 6.81404C31.0897 6.40208 31.0897 5.73467 30.6777 5.32264Z"
                fill="#377E82"
              />
              <path
                d="M18 0C17.417 0 16.9453 0.471727 16.9453 1.05469V5.34375C16.9453 5.92671 17.417 6.39844 18 6.39844C18.583 6.39844 19.0547 5.92671 19.0547 5.34375V1.05469C19.0547 0.471727 18.583 0 18 0Z"
                fill="#377E82"
              />
              <path
                d="M23.9051 10.5541C21.606 8.74006 18.6317 8.08622 15.7207 8.78232C12.3457 9.56278 9.62471 12.2416 8.80199 15.5956C7.97933 18.9705 8.92855 22.3877 11.3543 24.7713C12.2192 25.6362 12.7254 26.9932 12.7254 28.3854V28.6175C12.7254 29.208 13.1894 29.6722 13.7801 29.6722H22.2176C22.8083 29.6722 23.2723 29.208 23.2723 28.6175V28.3854C23.2723 27.0143 23.7996 25.6151 24.7278 24.708C26.4997 22.9151 27.491 20.5315 27.491 18.0003C27.491 15.0893 26.1832 12.3682 23.9051 10.5541ZM17.9989 14.8362C16.4077 14.8362 15.2494 15.8917 14.946 17.1135C14.808 17.6692 14.2489 18.0238 13.6678 17.8839C13.1034 17.7439 12.7583 17.1712 12.8985 16.6068C13.421 14.4974 15.4023 12.7268 17.9989 12.7268C18.5818 12.7268 19.0535 13.1986 19.0535 13.7815C19.0535 14.3645 18.5818 14.8362 17.9989 14.8362Z"
                fill="#377E82"
              />
              <path
                d="M13.7812 31.7812V32.8359C13.7812 34.5807 15.2006 36 16.9453 36H19.0547C20.7994 36 22.2188 34.5807 22.2188 32.8359V31.7812H13.7812Z"
                fill="#377E82"
              />
            </g>
            <defs>
              <clipPath id="clip0_868_26998">
                <rect width="36" height="36" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      case "goal":
        return (
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.125 32.625H34.875V34.875H1.125V32.625Z"
              fill="#377E82"
            />
            <path
              d="M33.75 1.125H23.625C23.3266 1.125 23.0405 1.24353 22.8295 1.4545C22.6185 1.66548 22.5 1.95163 22.5 2.25V12.375H24.75V9H33.75C34.0484 9 34.3345 8.88147 34.5455 8.6705C34.7565 8.45952 34.875 8.17337 34.875 7.875V2.25C34.875 1.95163 34.7565 1.66548 34.5455 1.4545C34.3345 1.24353 34.0484 1.125 33.75 1.125Z"
              fill="#377E82"
            />
            <path
              d="M17.4375 20.8125C17.4375 20.3649 17.2597 19.9357 16.9432 19.6193C16.6268 19.3028 16.1976 19.125 15.75 19.125H14.625C14.1774 19.125 13.7482 19.3028 13.4318 19.6193C13.1153 19.9357 12.9375 20.3649 12.9375 20.8125V30.375H17.4375V20.8125Z"
              fill="#377E82"
            />
            <path
              d="M27.5625 16.3125C27.5625 15.8649 27.3847 15.4357 27.0682 15.1193C26.7518 14.8028 26.3226 14.625 25.875 14.625H21.375C20.9274 14.625 20.4982 14.8028 20.1818 15.1193C19.8653 15.4357 19.6875 15.8649 19.6875 16.3125V30.375H27.5625V16.3125Z"
              fill="#377E82"
            />
            <path
              d="M10.6875 25.3125C10.6875 24.8649 10.5097 24.4357 10.1932 24.1193C9.87678 23.8028 9.44755 23.625 9 23.625H7.875C7.42745 23.625 6.99822 23.8028 6.68176 24.1193C6.36529 24.4357 6.1875 24.8649 6.1875 25.3125V30.375H10.6875V25.3125Z"
              fill="#377E82"
            />
          </svg>
        );
      case "target":
        return (
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_868_27043)">
              <path
                d="M22.2194 19.0551C22.2194 21.9647 19.8523 24.3319 16.9427 24.3319C14.0331 24.3319 11.6659 21.9647 11.6659 19.0551C11.6659 16.1455 14.0331 13.7782 16.9427 13.7782C18.0168 13.7782 19.0165 14.1017 19.8511 14.6551L22.9112 11.595C22.8422 11.5445 22.7728 11.4942 22.7022 11.4449C20.6269 9.99466 18.548 9.26172 16.5145 9.26172C16.3283 9.26172 16.1425 9.26784 15.957 9.28014C13.5034 9.44713 11.2175 10.5394 9.5221 12.3557C7.82244 14.1735 6.88672 16.5525 6.88672 19.0551C6.88672 21.7402 7.93297 24.2653 9.83267 26.165C11.7325 28.0648 14.2575 29.111 16.9426 29.111C19.6277 29.111 22.1528 28.0648 24.0525 26.165C25.9522 24.2652 26.9984 21.7402 26.9984 19.0551C26.9984 16.8449 26.1059 14.723 24.4944 12.9947L21.3424 16.1466C21.896 16.9812 22.2194 17.981 22.2194 19.0551Z"
                fill="#377E82"
              />
              <path
                d="M16.1989 18.3104L18.3105 16.1987C17.8967 16.0001 17.4335 15.8887 16.9447 15.8887C15.1982 15.8887 13.7773 17.3096 13.7773 19.0562C13.7773 20.8027 15.1982 22.2235 16.9447 22.2235C18.6912 22.2235 20.1121 20.8027 20.1121 19.0562C20.1121 18.5674 20.0006 18.1041 19.8021 17.6903L17.6905 19.8018L16.1989 18.3104Z"
                fill="#377E82"
              />
              <path
                d="M27.3012 10.1905L25.9889 11.5028C27.9958 13.6277 29.1097 16.276 29.1097 19.0558C29.1097 22.3044 27.8441 25.359 25.5459 27.6572C23.2477 29.9554 20.193 31.2211 16.9445 31.2211C13.696 31.2211 10.6412 29.9554 8.343 27.6572C6.04477 25.359 4.77914 22.3043 4.77914 19.0558C4.77914 16.0163 5.91687 13.1257 7.98258 10.9164C10.0436 8.70826 12.8264 7.3797 15.8174 7.17615C18.521 6.99748 21.2435 7.85157 23.9122 9.71654C24.0855 9.83762 24.2539 9.96235 24.4194 10.0893L25.8097 8.69898L26.0324 4.74805C23.3413 3.0313 20.2137 2.11133 16.9445 2.11133C12.4185 2.11133 8.16335 3.87385 4.96301 7.0742C1.76252 10.2745 0 14.5297 0 19.0557C0 23.5817 1.76252 27.8369 4.96294 31.0373C8.16335 34.2376 12.4185 36.0002 16.9445 36.0002C21.4705 36.0002 25.7257 34.2377 28.926 31.0373C32.1263 27.8368 33.8889 23.5817 33.8889 19.0557C33.8889 15.7865 32.9688 12.659 31.2521 9.96784L27.3012 10.1905Z"
                fill="#377E82"
              />
              <path
                d="M27.9609 8.04051L32.271 7.79759L36.0015 4.06702L31.9345 0L28.2039 3.7305L27.9609 8.04051Z"
                fill="#377E82"
              />
            </g>
            <defs>
              <clipPath id="clip0_868_27043">
                <rect width="36" height="36" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      default:
        return (
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.1843 4.88596C30.8275 4.88596 24.6725 5.2505 21.5271 8.39423C20.1653 9.75604 19.4852 11.9761 19.6618 14.4888C19.6847 14.8207 19.9054 15.1035 20.2225 15.2081C20.538 15.3111 20.8846 15.2147 21.1004 14.9613C22.8497 12.9031 25.0239 11.2536 27.5677 10.0585C27.7835 9.95551 28.0385 9.94732 28.2706 10.0291C28.475 10.1026 28.6352 10.2416 28.7186 10.4198C28.8918 10.786 28.8363 11.2683 28.2608 11.5396C28.2281 11.556 28.1987 11.5773 28.166 11.592C28.1546 11.5969 28.1415 11.5953 28.1317 11.6002C21.8639 14.5461 18.7823 19.6859 17.3828 24.9712C16.3627 18.3536 14.2081 14.4578 12.1728 12.0661C10.6998 10.1567 9.25133 9.14468 8.41436 8.57255C8.25903 8.46626 7.78989 8.14586 7.59533 7.9513C7.27654 7.63251 7.27654 7.11425 7.59533 6.79546C7.91411 6.47829 8.43398 6.47829 8.78871 6.8314C8.87538 6.90827 9.01431 7.00635 9.19087 7.12406L9.33639 7.22215C10.52 8.03299 12.7237 9.53864 14.6789 12.7805C14.8539 13.0698 15.1923 13.2252 15.5209 13.163C15.8544 13.1041 16.1159 12.8475 16.1813 12.5156C16.608 10.3364 16.4168 7.10276 14.473 5.159C11.3277 2.01688 5.17262 1.65234 0.817504 1.65234C0.366222 1.65227 0 2.01849 0 2.46969C0 6.82642 0.364536 12.9815 3.50826 16.1269C4.81935 17.438 6.84166 18.0265 8.85078 18.0265C10.4905 18.0265 12.0975 17.6161 13.3285 16.905C15.0074 20.3838 16.348 25.5809 16.348 33.531C16.348 33.9822 16.7142 34.3484 17.1654 34.3484C17.6167 34.3484 17.9829 33.9822 17.9829 33.531C17.9829 29.0958 18.7431 23.7728 21.5696 19.3915C22.7025 20.4983 24.6529 21.1996 26.814 21.265C26.9219 21.2683 27.0282 21.2699 27.1344 21.2699C29.33 21.2699 31.2705 20.5817 32.4917 19.3588C35.637 16.2135 35.9999 10.0584 35.9999 5.70163C36.0017 5.2505 35.6371 4.88596 35.1843 4.88596Z"
              fill="#377E82"
            />
          </svg>
        );
    }
  };

  return (
    <div className="main-carusel-card">
      {renderImage()}
      <div className="title-section title">{caruselTitle}</div>
      {renderText()}
      <a href="/" className="learn-more">
        <div>Learn more</div> <img src={ArrowRight} alt="arrow-right-white" />
      </a>
    </div>
  );
}

export default MainCaruselCard;
