import uuid from "react-uuid";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ConnectingMeaningfulConnections from "../../assets/create-meaningful-connections.png";
import ConnectingMeaningfulConnectionsMobile from "../../assets/create-meaningful-connections-mobile.svg";
import AppleStore from "../../assets/app-store.svg";
import GoogleStore from "../../assets/google-store.svg";
import Screens from "../../assets/screens.png";
import ScreensMobile from "../../assets/screens-mobile.png";
import Bubble1 from "../../assets/bubble2.svg";
import Bubble2 from "../../assets/bubble3.svg";
import Bubble3 from "../../assets/bubble5.svg";
import { ideas } from "./constants";
import "./index.scss";

function About() {
  const renderIdeas = () =>
    ideas.map((el, index) => (
      <div key={uuid()} className="section-idea-item">
        <div className="title-wrapper">
          <span className="number">{`0${index + 1}. `}</span>
          <span className="title">{el.title}</span>
        </div>
        <div className="text">{el.text}</div>
      </div>
    ));

  return (
    <main>
      <article className="about">
        <div className="header-absolute-wrapper">
          <Header isWhite={true} activeLink="About us" />
        </div>
        <section className="section-head-wrapper">
          <div className="section-head">
            <div className="title">
              At yulci, we are here to create a future ecosystem.
            </div>
            <div className="text">
              Social media can entertain. We go one step further and put people
              in the foreground.
            </div>
          </div>
        </section>
        <section className="section section-1">
          <div className="section-left-1">
            <img
              src={ConnectingMeaningfulConnectionsMobile}
              alt="create-meaningful-connections"
              className="create-meaningful-connections-mobile"
            />
            <img
              src={ConnectingMeaningfulConnections}
              alt="create-meaningful-connections"
              className="create-meaningful-connections"
            />
          </div>
          <div className="section-right-1">
            <div className="title-section title-small">
              Our values. Achieve great things with small steps.
            </div>
            <div className="text-section-wrapper">
              <div className="text-section inner-border">
                At yulci we want to raise awareness and promote a sustainable
                economy to new people every day. We are a young team consisting
                of former students who founded yulci in 2023 as a sustainable
                social media platform. We rely on voluntary pricing models,
                which are in line with our ideas of a modern circular economy.
                We work on being able to improve daily topics on a small scale
                and to promote exchange. We deal with everyday topics such as
                mobility, food, travel or fashion. Download our app and share
                your opinion with us.
              </div>
            </div>
          </div>
          <img src={Bubble3} alt="bubble-3" className="bubble-3" />
        </section>
        <section className="section-idea-wrapper">
          <div className="section-idea">{renderIdeas()}</div>
        </section>
        <section className="section section-2">
          <div className="section-left-2">
            <div>
              <div className="title-section-left">
                Download our app, soon <br /> available for IOS & Android.
              </div>
              <div className="stores-wrapper">
                <a href="/">
                  <img src={AppleStore} alt="apple-store" />
                </a>
                <a href="/">
                  <img
                    src={GoogleStore}
                    alt="google-store"
                    className="google-store"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="section-right-2">
            <img
              src={ScreensMobile}
              alt="mobile-1"
              className="screens-mobile"
            />
            <img src={Bubble1} alt="bubble-1" className="bubble-mobile bubble-1" />
            <img src={Bubble2} alt="bubble-2" className="bubble-mobile bubble-2" />
            <img src={Screens} alt="mobile-1" className="screens-desktop" />
          </div>
        </section>
      </article>
      <Footer />
    </main>
  );
}

export default About;
