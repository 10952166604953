import { useState, useRef, useEffect } from "react";
import { isMobile } from "is-mobile";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import YulciLogo from "../../assets/yulci-logo.svg";
import YulciLogoWhite from "../../assets/yulci-logo-white.svg";
import Bubble1 from "../../assets/bubble1.svg";
import Apple from "../../assets/apple.svg";
import Goolge from "../../assets/google.svg";
import Close from "../../assets/close.svg";
import variables from "../../variables.scss";
import { links } from "./constants";
import "./index.scss";

export const useScrollBodyLock = () => {
  const lock = () => {
    if (isMobile()) {
      const scrollY = `${window.scrollY}px`;
      const body = document.body;
      body.style.position = "fixed";
      body.style.top = `-${scrollY}`;
      return;
    }
  };

  const unlock = () => {
    if (isMobile()) {
      const body = document.body;
      const scrollY = body.style.top;
      const parsedScrollY = parseInt(scrollY || "0") * -1;

      body.style.position = "";
      body.style.top = "";

      if (parsedScrollY) {
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
      return;
    }
  };

  return { lock, unlock };
};

const customStyles = {
  content: {
    width: "100%",
    inset: 0,
    padding: 0,
  },
};

function Header({ isWhite, activeLink }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const ref = useRef(null);
  const { lock, unlock } = useScrollBodyLock();

  useEffect(() => {
    function handleWindowResize() {
      if (ref.current.clientWidth >= 800) {
        setIsTablet(true);
      }

      if (ref.current.clientWidth < 800) {
        setIsTablet(false);
      }
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isOpenModal) {
      if (isTablet) {
        unlock();
      } else {
        lock();
      }
    }

  }, [isTablet]);

  const styles = {
    link: { backgroundColor: variables.boldgreen },
  };

  const stylesIsWhite = isWhite && {
    header: { borderBottom: "1px solid rgba(255, 255, 255, .3" },
    content: { backgroundColor: "transparent" },
    linkText: { color: variables.white },
  };

  const renderLinks = () =>
    links.map((el) => (
      <Link to={"/about"} style={stylesIsWhite?.linkText}>
        <div className="link" style={activeLink === el ? styles?.link : {}}>
          {el}
        </div>
      </Link>
    ));

  // console.log("isOpenModal", isOpenModal);

  return (
    <>
      <div className="header" style={stylesIsWhite?.header} ref={ref}>
        <div className="content" style={stylesIsWhite?.content}>
          <Link to={"/"} className="main-logo-wrapper">
            <img
              src={isWhite ? YulciLogoWhite : YulciLogo}
              alt="yulci-logo"
              className="main-logo"
            />
          </Link>
          <div className="header-content">
            {renderLinks()}
            <div
              className={`container-bar ${isOpenModal ? "change" : ""}`}
              onClick={() => setIsOpenModal(!isOpenModal)}
            >
              <div className="bar1" />
              <div className="bar2" />
              <div className="bar3" />
            </div>
          </div>
        </div>
      </div>

      {isOpenModal && (
        <Modal
          isOpen={isOpenModal}
          onAfterOpen={lock}
          onAfterClose={unlock}
          shouldFocusAfterRender
          style={customStyles}
          className="modal-wrapper"
          overlayClassName="modal-wrapper"
        >
          <div className="modal">
            <div className="modal-header">
              <Link to={"/"} className="main-logo-wrapper">
                <img src={YulciLogo} alt="yulci-logo" className="main-logo" />
              </Link>
              <div
                className={`container-bar ${isOpenModal ? "change" : ""}`}
                onClick={() => setIsOpenModal(!isOpenModal)}
              >
                <img src={Close} alt="cross" />
              </div>
            </div>
            <img src={Bubble1} alt={"bubble1"} className="bubble-1" />
            <div className="link">
              <Link to={"/about"}>About</Link>
            </div>
            <div className="media-mininazed-wrapper">
              <div className="media-mininazed">
                <div className="media-mininazed-title">
                  Download our mobile app
                </div>
                <div className="media-mininazed-media">
                  <img src={Apple} alt={"apple"} className="shop-minimized" />
                  <img src={Goolge} alt={"google"} className="shop-minimized" />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Header;
