import React from "react";
import { useSnapCarousel } from "react-snap-carousel";
import ArrowRightGreen from "../../assets/arrow-right-green.svg";
import ArrowLeftGreen from "../../assets/arrow-left-green.svg";
import SlashGreeen from "../../assets/slash-greeen.svg";
import "./index.scss";

const styles = {
  nextPrevButton: { cursor: "pointer" },
  nextPrevButtonDisabled: {
    opacity: 0.3,
    cursor: "default",
  },
};

export const Carousel = ({ children }) => {
  const { scrollRef, goTo, pages, activePageIndex, prev, next } =
    useSnapCarousel();
  return (
    <div className="carusel-main">
      <div className="controls controls-desktop">
        <button
          className="button"
          style={{
            ...styles.nextPrevButton,
            ...(activePageIndex === 0 ? styles.nextPrevButtonDisabled : {}),
          }}
          onClick={() => prev()}
        >
          <img src={ArrowLeftGreen} alt="arrow-left-green" className="green-arrow" />
        </button>
        <img src={SlashGreeen} alt="slash-greeen" className="green-slash" />
        <button
          className="button"
          style={{
            ...styles.nextPrevButton,
            ...(activePageIndex === pages.length - 1
              ? styles.nextPrevButtonDisabled
              : {}),
          }}
          onClick={() => next()}
        >
          <img src={ArrowRightGreen} alt="arrow-right-green" className="green-arrow" />
        </button>
      </div>
      <div className="scroll" ref={scrollRef}>
        {children}
      </div>
      <div className="mobile-controls">
        <div className="dot-wrapper">
          {pages.map((_, i) => (
            <button
              className="button button-dots"
              key={i}
              onClick={() => goTo(i)}
            >
              <div
                className="dot"
                style={{
                  backgroundColor:
                    i === activePageIndex ? "#BDC4C4" : "#E5E7E7",
                }}
              />
            </button>
          ))}
        </div>
        <div className="controls">
          <button
            className="button"
            style={{
              ...styles.nextPrevButton,
              ...(activePageIndex === 0 ? styles.nextPrevButtonDisabled : {}),
            }}
            onClick={() => prev()}
          >
            <img src={ArrowLeftGreen} alt="arrow-left-green" className="green-arrow" />
          </button>
          <img src={SlashGreeen} alt="slash-greeen" className="green-slash" />
          <button
            className="button button-mobile-last"
            style={{
              ...styles.nextPrevButton,
              ...(activePageIndex === pages.length - 1
                ? styles.nextPrevButtonDisabled
                : {}),
            }}
            onClick={() => next()}
          >
            <img src={ArrowRightGreen} alt="arrow-right-green" className="green-arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export const CarouselItem = ({ children }) => (
  <div className="item">{children}</div>
);
