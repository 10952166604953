export const ideas = [
  {
    title: "Our vision",
    text: "In the long-term, we want to offer a platform that connects people and makes conscious decisions easy and intuitive. In this way we promote the health of society and well-being.",
  },
  {
    title: "Our mission",
    text: "We are developing a digital ecosystem that differs from others. We work with all stakeholders to promote a closed and sustainable circular economy.",
  },
  {
    title: "Our purpose",
    text: "Our goal is to show people that they can easily integrate small things into everyday life and how easy it can be.",
  },
];
