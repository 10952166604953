import { Link } from 'react-router-dom';
import BtnWhite from '../../ui/BtnWhite';
import YulciLogoWhite from '../../assets/yulci-logo-white.svg';
import Facebook from '../../assets/facebook-round-white.svg';
import Instagram from '../../assets/instagram-round-white.svg';
import Linkedin from '../../assets/linkedin-round-white.svg';
import './index.scss';

// <a href="/">Become a partner</a>
// <a href="/">
//   <BtnWhite />
// </a>
// <a href="/">Privacy Policy</a> |{" "}
// <Link to={"/impresssum"}>Terms of Use</Link> |{" "}

function Footer() {
  return (
    <div className="footer">
      <div className="content">
        <div className="common-wrapper">
          <div className="common-left">
            <img src={YulciLogoWhite} alt="yulci-logo-white" className="logo" />
            <div className="text">Achieve great things with small steps.</div>
            <div className="media-group">
              <a
                href="https://www.instagram.com/yulciapp"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Facebook} alt="facebook-round-white" />
              </a>
              <a
                href="https://www.facebook.com/yulciapp"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="facebook-round-white" />
              </a>
              <a
                href="https://www.linkedin.com/company/yulci/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Linkedin} alt="facebook-round-white" />
              </a>
            </div>
          </div>
          <div className="common-right">
            <Link to={'/about'}>About</Link>
          </div>
        </div>
      </div>
      <div className="terms-cpyright-wrapper">
        <div className="terms-cpyright-content">
          <div className="terms-cpyright">
            <div className="terms-links">
              <Link to={'/privacy'}>Privacy Policy</Link>
              <span>{' | '}</span>
              <Link to={'/terms'}>Terms of Use</Link>
              <span>{' | '}</span>
              <Link to={'/impressum'}>Impressum</Link>
            </div>
            <div>Copyright 2023</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
